import Flag from './flag.png'
import Logo from './logo_bench.png'
import LogoFull from './cour_full_logo.png'
import LogoMain from './logo_large.png'
import LogoSmall from './logo_small.png'

export {
    Flag,
    Logo,
    LogoFull,
    LogoMain,
    LogoSmall
}